import { client } from "../../../../core/service/client";

const emailInput = document.querySelector("#email");
const passwordInput = document.querySelector("#password");
const button = document.querySelector("#login_btn");

export async function loginUseCase(e) {
  e.preventDefault();

  if (!emailInput || !passwordInput || !button) {
    console.error("Elementos de input ou botão não encontrados.");
    return;
  }

  if (isInputValid(emailInput, "Email") && isInputValid(passwordInput, "Senha", 8)) {
    const email = emailInput.value;
    const password = passwordInput.value;

    toggleButtonLoading(button, true);

    try {
      const response = await client.post(`/login`, { email, password });

      const { user } = response.data;

      saveSessionData({ city: user.city, role: user.role });
      window.location.replace("/module/home/home.html");
    } catch (err) {
      const errorMessage = err.response?.data?.message || "Estamos fora do ar, tente novamente mais tarde";
      showToast(errorMessage, "warning", "#ef233c");
    } finally {
      toggleButtonLoading(button, false);
    }
  }
}

function showToast(message, className, background) {
  Toastify({
    text: message,
    className: className,
    style: {
      background: background,
      color: "white",
    },
  }).showToast();
}


function toggleButtonLoading(button, isLoading) {
  button.classList.toggle("isLoading", isLoading);
}

function saveSessionData({ city, role }) {
  localStorage.setItem("city", city); // Armazenar informações não sensíveis
  localStorage.setItem("role", role);
}


function isInputValid(input, inputName, minLength = 1) {
  const value = input.value.trim();

  if (value === "") {
    showToast(`${inputName} é obrigatório`, "info", "linear-gradient(to right, #00b09b, #96c93d)");
    return false;
  }

  // Verifica se o e-mail é válido
  if (inputName === "Email" && !validateEmail(value)) {
    showToast("Formato de e-mail inválido", "info", "linear-gradient(to right, #00b09b, #96c93d)");
    return false;
  }

  if (value.length < minLength) {
    showToast(`${inputName} deve ter pelo menos ${minLength} caracteres`, "info", "linear-gradient(to right, #00b09b, #96c93d)");
    return false;
  }

  return true;
}

function validateEmail(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}

